import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import { useToasters } from 'hooks/useToasters';
import React, { useCallback, useState } from 'react';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(3),
    '& > h2': {
      ...TYPOGRAPHY.TITLE_3,
    },
  },

  dialogContent: {
    paddingTop: `0 !important`,
    paddingBottom: `${theme.spacing(4)}px !important`,
  },

  dialogContentText: {
    ...TYPOGRAPHY.REGULAR_REGULAR,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(3),
  },

  emailEnabledSection: {
    marginTop: theme.spacing(3),
  },

  radioWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: 0,

    '& + &': {
      marginTop: theme.spacing(2),
    },
  },

  radioGroupTitle: {
    ...TYPOGRAPHY.EXTRA_SMALL_BOLD,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(),
  },

  radioLabel: {
    ...TYPOGRAPHY.REGULAR_MEDIUM,
  },

  radioDescription: {
    marginLeft: theme.spacing(4),
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
  },
}));

interface Props {
  applicationId: string;
  skipBillingSettings?: boolean;
  className?: string;
}

export const ShareApplicationButton: React.FC<Props> = ({ applicationId, className, skipBillingSettings }) => {
  const classes = useStyles();
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const { showToaster } = useToasters();

  const handleCopyLink = useCallback(() => {
    const link = `${window.location.origin}${insertIdToRoute(
      `/${ROUTES.application.ROOT}/${ROUTES.application.FORM}`,
      applicationId,
    )}/general`;
    navigator.clipboard.writeText(link);
    showToaster('Link is copied to clipboard');
    setLinkModalOpen(false);
  }, [applicationId, showToaster]);

  const handleClick = useCallback(() => {
    if (skipBillingSettings) {
      handleCopyLink();
    } else {
      setLinkModalOpen(true);
    }
  }, [handleCopyLink, skipBillingSettings]);

  return (
    <>
      <Button variant='outlined' className={className} onClick={handleClick} startIcon={<ShareRoundedIcon />}>
        Share Questionnaire
      </Button>
      <Dialog fullWidth={true} open={linkModalOpen} onClose={() => setLinkModalOpen(false)}>
        <DialogTitle className={classes.dialogTitle}>Share this Link</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            Share this link with your client so they can complete the Questionnaire
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkModalOpen(false)}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={handleCopyLink}>
            <LinkRoundedIcon />
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
