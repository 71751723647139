import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import cx from 'classnames';
import { Badge, BadgeVariant } from 'components/Badge';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { Column, Sorter, SortOrder, Table } from 'components/Table';
import dayjs from 'dayjs';
import { ApplicationWithAgentDTO } from 'dtos/application';
import {
  DeepScanM365AuditLogSignIn,
  DeepScanM365AuditLogSignIns,
  // DeepScanM365ControlProfile,
  // DeepScanM365ControlScore,
  // DeepScanM365SecurityScoreControlProfiles,
  DeepScanM365SecurityScores,
  DeepScanM365SubscribedSku,
  DeepScanM365User,
  DeepScanM365Users,
} from 'dtos/deep-scan';
import { useM365LoginMapChart } from 'hooks/charts/useM365LoginMapChart';
import { useChartImage } from 'hooks/useChartImage';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { useApplicationSecurityChartsUrls } from 'queries/useCharts';
import { useDeepScan, useDeepScanM365SecurityData } from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { MFA_STATUSES, SIGN_IN_STATUSES } from 'telivy-constants';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { getMFAScoreRanking, getSignInStatusRanking } from 'templates/SecurityAssessments/helpers';
import { ApplicationViewContainer } from 'views/agent/views/application-details/views/ApplicationViewContainer';

import { ScoreSecurityCard } from '../../components/common/ScoreSecurityCard';
import { ScoreBox } from '../../components/ScoreBox';
import { SecurityCard } from '../../components/SecurityCard';
import { SubscribedSkuDetailsModal } from '../../components/SubscribedSkuDetailsModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  findings: {
    color: COLORS.GREY_3,
  },

  section: {
    marginBottom: theme.spacing(4),
  },

  scanResultsWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: theme.spacing(1.25),
    },
  },

  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  boxBorder: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
  },

  mapContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  mapContainerItem: {
    flex: 1,
    flexDirection: 'column',
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    '&:last-child': {
      paddingTop: 0,
    },
  },

  summary: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    gap: theme.spacing(2),
  },
  item: {
    border: `solid 1px ${COLORS.GREY_5}`,
    backgroundColor: 'rgba(249, 251, 252, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  description: {
    ...TYPOGRAPHY.SMALL_BOLD,
    color: COLORS.GREY_2,
    marginTop: theme.spacing(1),
  },
  number: {
    ...TYPOGRAPHY.TITLE_2,
    fontSize: 48,
    color: COLORS.TEXT,
  },
  red: {
    color: COLORS.RED_1,
  },
  green: {
    color: COLORS.GREEN_1,
  },
  blue: {
    color: COLORS.BLUE_1,
  },

  chart: {
    // maxHeight: 600,
  },
  chartContainer: {
    marginBottom: theme.spacing(2),
  },
  disclaimer: {
    width: 700,
    margin: '0 auto',
  },
  errorBox: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'start',
  },

  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },

  noBorder: {
    border: 'none',
  },
  clickButton: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: 'normal',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },

  actionButton: {
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginRight: theme.spacing(0.5),
  },
  securityCard: {
    flex: 1,
  },
}));

interface Props {
  application: ApplicationWithAgentDTO;
  isAdmin: boolean;
}

interface M365LoginByLocationData {
  location: string;
  successful: number;
  failed: number;
}

interface M365LoginByFailureData {
  reason: string;
  user: string;
  failed: number;
}

const STRONG_THRESHOLD = 80;
const MODERATE_THRESHOLD = 40;
const PER_PAGE = 8;
const PER_PAGE_LOCATIONS = 10;
export const AUTH_METHODS: Record<string, string> = {
  '#microsoft.graph.emailAuthenticationMethod': 'Email',
  '#microsoft.graph.fido2AuthenticationMethod': 'FIDO2 Security Key',
  '#microsoft.graph.microsoftAuthenticatorAuthenticationMethod': 'MS Authenticator',
  '#microsoft.graph.passwordAuthenticationMethod': 'Password',
  '#microsoft.graph.phoneAuthenticationMethod': 'Phone',
  '#microsoft.graph.softwareOathAuthenticationMethod': 'TOTP',
  '#microsoft.graph.temporaryAccessPassAuthenticationMethod': 'Temporary Access Pass',
  '#microsoft.graph.windowsHelloForBusinessAuthenticationMethod': 'Windows Hello for Business',
};

export const getScoreBadgeVariant = (scoreInPercentage: number | null): BadgeVariant => {
  if (scoreInPercentage && scoreInPercentage > STRONG_THRESHOLD) {
    return 'green';
  } else if (scoreInPercentage && scoreInPercentage > MODERATE_THRESHOLD) {
    return 'yellow';
  } else {
    return 'red';
  }
};

export interface DeepScanM365UserRow {
  id: string;
  accountType: string;
  lastLogin?: string;
  accountEnabled?: boolean;
  displayName: string;
  mail: string;
  mfaStatus: number | null;
  authenticationMethods: string;
  successes?: number;
  failures?: number;
  adminRole?: string;
}

const scrollAccountStatus = () => {
  const section = document.querySelector('#account-status');
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const DeepScanM365Security = ({ application, isAdmin }: Props) => {
  const classes = useStyles();

  const [selectedSubscribedSku, setSelectedSubscribedSku] = useState<DeepScanM365SubscribedSku | undefined>(undefined);
  const handleClose = () => {
    setSelectedSubscribedSku(undefined);
  };

  const deepScanId = application.deepScanId || '';
  const { data: profile, isLoading: isLoadingProfile } = useDeepScanM365SecurityData<DeepScanM365User>(
    deepScanId,
    'profile',
    {
      enabled: Boolean(deepScanId),
    },
  );
  const { data: securityScore, isLoading: isLoadingSecurityScore } =
    useDeepScanM365SecurityData<DeepScanM365SecurityScores>(deepScanId, 'securityScore', {
      enabled: Boolean(deepScanId),
    });
  const { data: usersRaw, isLoading: isLoadingUsers } = useDeepScanM365SecurityData<DeepScanM365Users>(
    deepScanId,
    'users',
    { enabled: Boolean(deepScanId) },
  );
  const { data: auditLogsSignIns, isLoading: isLoadingAuditLogsSignIns } =
    useDeepScanM365SecurityData<DeepScanM365AuditLogSignIns>(deepScanId, 'auditLogsSignIns', {
      enabled: Boolean(deepScanId),
    });
  // const { data: securityScoreControlProfiles, isLoading: isLoadingSecurityScoreControlProfiles } =
  //   useDeepScanM365SecurityData<DeepScanM365SecurityScoreControlProfiles>(deepScanId, 'securityScoreControlProfiles', {
  //     enabled: Boolean(deepScanId),
  //   });

  const [showOnlyNoMfa, setShowOnlyNoMfa] = useState(false);

  const [userPage, setUserPage] = useState(0);
  const [userSorter, setUserSorter] = useState<Sorter<keyof DeepScanM365UserRow> | undefined>({
    key: 'displayName',
    order: SortOrder.ASC,
  });

  // const [actionPage, setActionPage] = useState(0);
  // const [actionSorter, setActionSorter] = useState<Sorter<keyof DeepScanM365ControlScore> | undefined>({
  //   key: 'scoreImpact',
  //   order: SortOrder.DESC,
  // });

  const [locationPage, setLocationPage] = useState(0);
  const [locationSorter, setLocationSorter] = useState<Sorter<keyof M365LoginByLocationData> | undefined>({
    key: 'successful',
    order: SortOrder.DESC,
  });

  const [failuresPage, setFailuresPage] = useState(0);

  const { data: deepScan } = useDeepScan(deepScanId);
  const enableCharts = useChartsEnabled(deepScan, application);

  const { data: charts } = useApplicationSecurityChartsUrls(application.id);
  // const mapChart = useChartImage(
  //   { chartContainerClassName: classes.noBorder },
  //   charts?.m365LoginStatusMap,
  //   deepScan,
  //   application,
  // );
  const securityScoreChart = useChartImage(
    { className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365SecurityScoreHistory,
    deepScan,
    application,
  );
  const failedLoginsChart = useChartImage(
    { title: 'Failed Logins by User', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365FailedLogins,
    deepScan,
    application,
  );
  const loginActivityChart = useChartImage(
    { title: 'Login Activity', className: classes.chart, chartContainerClassName: classes.chartContainer },
    charts?.m365LoginActivity,
    deepScan,
    application,
  );

  const onClickNoMfaCount = () => {
    setShowOnlyNoMfa(true);
    scrollAccountStatus();
  };

  const securityScoreData = useMemo(
    () => (securityScore?.value || []).filter((s) => s.averageComparativeScores.length > 0)[0],
    [securityScore],
  );
  const users = useMemo(() => usersRaw?.value || [], [usersRaw]);
  const loginAudits = useMemo(() => auditLogsSignIns?.value || [], [auditLogsSignIns]);

  const { chart: mapChart } = useM365LoginMapChart(loginAudits);

  const loginByLocation = useMemo(() => {
    const res: Record<string, DeepScanM365AuditLogSignIn[]> = {};
    if (auditLogsSignIns?.value) {
      for (const al of auditLogsSignIns?.value || []) {
        const location = [al.location.city, al.location.state, al.location.countryOrRegion].join(', ');
        res[location] = res[location] || [];
        res[location].push(al);
      }
    }
    return res;
  }, [auditLogsSignIns]);

  // const actionsDetails = useMemo(() => {
  //   const res: Record<string, DeepScanM365ControlProfile> = {};
  //   if (securityScoreControlProfiles?.value) {
  //     for (const cp of securityScoreControlProfiles?.value || []) {
  //       res[cp.id] = cp;
  //     }
  //   }
  //   return res;
  // }, [securityScoreControlProfiles]);

  // const actions: DeepScanM365ControlScore[] = useMemo(() => {
  //   return (securityScoreData?.controlScores || []).map((scoreData) => {
  //     return {
  //       ...scoreData,
  //       title: actionsDetails[scoreData.controlName]?.title,
  //       maxScore: actionsDetails[scoreData.controlName]?.maxScore,
  //       scoreImpact:
  //         (((actionsDetails[scoreData.controlName]?.maxScore || 0) - scoreData.score) * 100) /
  //         (securityScoreData?.maxScore || 1),
  //     };
  //   });
  // }, [securityScoreData, actionsDetails]);

  const securityScorePercent = useMemo(() => {
    if (securityScoreData) {
      return ((securityScoreData.currentScore || 0) * 100) / (securityScoreData.maxScore || 1);
    }
    return 0;
  }, [securityScoreData]);

  const benchmarkPercent = useMemo(() => {
    if (securityScoreData) {
      return securityScoreData.averageComparativeScores.filter((d) => d.basis === 'TotalSeats')[0]?.averageScore;
    }
    return 'N/A';
  }, [securityScoreData]);

  const subscribedSkuColumns = useMemo((): Column<DeepScanM365SubscribedSku, keyof DeepScanM365SubscribedSku>[] => {
    return [
      {
        title: 'Account Name',
        render: (row) => row.accountName,
      },
      {
        title: 'SKU Part Number',
        render: (row) => row.skuPartNumber,
      },
      {
        title: 'Capability Status',
        render: (row) => row.capabilityStatus,
      },
      {
        title: 'Consumed Units',
        render: (row) => row.consumedUnits,
      },
      {
        title: 'Prepaid Units Enabled',
        render: (row) => row.prepaidUnits.enabled,
      },
      {
        title: 'Prepaid Units Warning',
        render: (row) => row.prepaidUnits.warning,
      },
      {
        title: 'Prepaid Units Locked Out',
        render: (row) => row.prepaidUnits.lockedOut,
      },
      {
        title: 'Prepaid Units Suspended',
        render: (row) => row.prepaidUnits.suspended,
      },
      {
        title: 'Service Plans',
        render: (row) => (
          <Button className={classes.actionButton} onClick={() => setSelectedSubscribedSku(row)}>
            Details
          </Button>
        ),
      },
    ];
  }, [classes]);

  const subscribedSkuData = useMemo(() => {
    return profile?.subscribedSkus?.value || [];
  }, [profile]);

  // const actionColumns = useMemo((): Column<DeepScanM365ControlScore, keyof DeepScanM365ControlScore>[] => {
  //   return [
  //     {
  //       title: 'Recommended Action',
  //       sortKey: 'controlName',
  //       render: (row) => row.title || row.controlName,
  //     },
  //     {
  //       title: 'Score Impact',
  //       sortKey: 'score',
  //       render: (row) => ((row.scoreImpact || 0) > 0 ? `+${(row.scoreImpact || 0).toFixed(2)}%` : ''),
  //     },
  //     {
  //       title: 'Score',
  //       sortKey: 'scoreInPercentage',
  //       render: (row) => (
  //         <Badge bordered variant={getScoreBadgeVariant(row.scoreInPercentage)} style={{ minWidth: 80, width: 80 }}>
  //           {row.scoreInPercentage} %
  //         </Badge>
  //       ),
  //     },
  //     {
  //       title: 'Points achieved',
  //       sortKey: 'score',
  //       render: (row) => `${row.score} / ${row.maxScore || 'N/A'}`,
  //     },
  //   ];
  // }, []);

  // const sortedActions = useMemo(() => {
  //   if (actionSorter) {
  //     const data = sortBy(actions, actionSorter.key);

  //     if (actionSorter.order === SortOrder.DESC) {
  //       return data.reverse();
  //     }

  //     return data;
  //   }

  //   return sortBy(actions, (action) => [action.scoreInPercentage, action.scoreImpact || 0]);
  // }, [actions, actionSorter]);

  // const actionsData = useMemo(() => {
  //   return (sortedActions || []).slice(actionPage * PER_PAGE, actionPage * PER_PAGE + PER_PAGE);
  // }, [actionPage, sortedActions]);

  const userColumns = useMemo((): Column<DeepScanM365UserRow, keyof DeepScanM365UserRow>[] => {
    const accountTypeColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Account Type',
      sortKey: 'accountType',
      render: (row: DeepScanM365UserRow) => row.accountType,
    };

    const signInStatusColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Sign-in Status',
      sortKey: 'accountEnabled',
      render: (row: DeepScanM365UserRow) => (
        <ScoreBox
          ranking={getSignInStatusRanking(
            row.accountEnabled !== undefined
              ? row.accountEnabled
                ? SIGN_IN_STATUSES.ENABLED
                : SIGN_IN_STATUSES.DISABLED
              : SIGN_IN_STATUSES.NOT_APPLICABLE,
          )}
          label={
            row.accountEnabled !== undefined
              ? row.accountEnabled
                ? SIGN_IN_STATUSES.ENABLED
                : SIGN_IN_STATUSES.DISABLED
              : SIGN_IN_STATUSES.NOT_APPLICABLE
          }
        />
      ),
    };

    const lastLoginColumn: Column<DeepScanM365UserRow, keyof DeepScanM365UserRow> = {
      title: 'Last Login',
      sortKey: 'lastLogin',
      render: (row: DeepScanM365UserRow) => (row.lastLogin ? dayjs(row.lastLogin).format('M/D/YYYY h:mm A') : 'N/A'),
    };

    return [
      {
        title: 'Name',
        sortKey: 'displayName',
        render: (row) => row.displayName,
      },
      {
        title: 'Email',
        sortKey: 'mail',
        render: (row) => row.mail,
      },
      ...(auditLogsSignIns?.error ? [] : [accountTypeColumn, signInStatusColumn]),
      {
        title: 'Admin Role',
        sortKey: 'adminRole',
        render: (row) => row.adminRole,
      },
      {
        title: 'MFA Status',
        sortKey: 'mfaStatus',
        render: (row) => {
          let mfaStatus = MFA_STATUSES.NOT_APPLICABLE;

          if (row.mfaStatus != null) {
            mfaStatus = row.mfaStatus > 0 ? MFA_STATUSES.ENFORCED : MFA_STATUSES.NOT_ENFORCED;
          }

          return <ScoreBox ranking={getMFAScoreRanking(mfaStatus)} label={mfaStatus} />;
        },
      },
      {
        title: 'Authentication Methods',
        sortKey: 'authenticationMethods',
        render: (row) => row.authenticationMethods,
      },
      ...(auditLogsSignIns?.error ? [] : [lastLoginColumn]),
      {
        title: 'Successful Logins',
        sortKey: 'successes',
        render: (row) => row.successes,
      },
      {
        title: 'Login Failures',
        sortKey: 'failures',
        render: (row) => row.failures,
      },
    ];
  }, [auditLogsSignIns?.error]);

  const sortedUsers = useMemo(() => {
    const filteredUsers = users.filter((u) => u.mail);

    if (userSorter) {
      const data = sortBy(filteredUsers, userSorter.key);

      if (userSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(filteredUsers, (user) => user.displayName);
  }, [users, userSorter]);

  const usersData: DeepScanM365UserRow[] = useMemo(() => {
    return (sortedUsers || [])
      .map((row: DeepScanM365User) => {
        let mfaStatus = null;
        if (!row.mailboxSettings?.userPurpose || row.mailboxSettings?.userPurpose != 'shared') {
          mfaStatus = (row.authenticationMethods || []).length > 1 ? 1 : 0;
        }

        const authenticationMethods = (row.authenticationMethods || [])
          .map((m) => AUTH_METHODS[m['@odata.type']] || 'N/A')
          .join(', ');

        const lastLogin =
          row.signInActivity?.lastNonInteractiveSignInDateTime || row.signInActivity?.lastSignInDateTime;

        return {
          id: row.id,
          accountType: row.mailboxSettings?.userPurpose || 'n/a',
          lastLogin,
          accountEnabled: row.accountEnabled,
          adminRole: (row.memberOf?.value || [])
            .map((r) => (r.displayName.indexOf('Admin') > -1 ? r.displayName.split(' ')[0] : ''))
            .filter((dn) => dn)
            .join(', '),
          displayName: row.displayName,
          mail: row.mail,
          mfaStatus,
          authenticationMethods,
          successes: loginAudits.filter((l) => l.userId === row.id && l.status.errorCode === 0).length,
          failures: loginAudits.filter((l) => l.userId === row.id && l.status.errorCode !== 0).length,
        };
      })
      .filter((u) => !showOnlyNoMfa || u.mfaStatus != 1)
      .slice(userPage * PER_PAGE, userPage * PER_PAGE + PER_PAGE);
  }, [userPage, sortedUsers, loginAudits, showOnlyNoMfa]);

  const orphanedAccounts = useMemo(() => {
    return (usersData || []).filter((u) => moment().diff(moment(u.lastLogin), 'days') >= 30);
  }, [usersData]);

  const locationsFullData: M365LoginByLocationData[] = useMemo(() => {
    return Object.keys(loginByLocation).map((location) => {
      return {
        location,
        successful: (loginByLocation[location] || []).filter((l) => l.status.errorCode === 0).length,
        failed: (loginByLocation[location] || []).filter((l) => l.status.errorCode !== 0).length,
      };
    });
  }, [loginByLocation]);

  const locationColumns = useMemo((): Column<M365LoginByLocationData, keyof M365LoginByLocationData>[] => {
    return [
      {
        title: 'Location',
        sortKey: 'location',
        render: (row) => row.location,
      },
      {
        title: 'Successful logins',
        sortKey: 'successful',
        render: (row) => row.successful,
      },
      {
        title: 'Login Failures',
        sortKey: 'failed',
        render: (row) => row.failed,
      },
    ];
  }, []);

  const sortedLocations = useMemo(() => {
    if (locationSorter) {
      const data = sortBy(locationsFullData, locationSorter.key);

      if (locationSorter.order === SortOrder.DESC) {
        return data.reverse();
      }

      return data;
    }

    return sortBy(locationsFullData, (l) => l.location);
  }, [locationsFullData, locationSorter]);

  const locationsData = useMemo(() => {
    return (sortedLocations || []).slice(
      locationPage * PER_PAGE_LOCATIONS,
      locationPage * PER_PAGE_LOCATIONS + PER_PAGE_LOCATIONS,
    );
  }, [locationPage, sortedLocations]);

  const loginByFailureReasons = useMemo(() => {
    const res: Record<string, M365LoginByFailureData> = {};
    if (auditLogsSignIns?.value) {
      for (const al of (auditLogsSignIns?.value || []).filter((l) => l.status.errorCode !== 0)) {
        const failureReason = `${al.status.failureReason}:${al.userPrincipalName}`;

        res[failureReason] = res[failureReason] || {
          reason: al.status.failureReason,
          user: al.userPrincipalName,
          failed: 0,
        };

        res[failureReason].failed += 1;
      }
    }
    return res;
  }, [auditLogsSignIns]);

  const failuresColumns = useMemo((): Column<M365LoginByFailureData, keyof M365LoginByFailureData>[] => {
    return [
      {
        title: 'Failure Reason',
        render: (row) => row.reason,
      },
      {
        title: 'User',
        width: '20%',
        render: (row) => row.user,
      },
      {
        title: 'Login Failures',
        width: '10%',
        render: (row) => row.failed,
      },
    ];
  }, []);

  const sortedFailureReasons = useMemo(() => {
    return sortBy(Object.values(loginByFailureReasons), (l) => l.failed).reverse();
  }, [loginByFailureReasons]);

  const failreReasonsData = useMemo(() => {
    return (sortedFailureReasons || []).slice(
      failuresPage * PER_PAGE_LOCATIONS,
      failuresPage * PER_PAGE_LOCATIONS + PER_PAGE_LOCATIONS,
    );
  }, [failuresPage, sortedFailureReasons]);

  const accountNoMfa = useMemo(() => {
    return (users || []).filter((u) => (u.authenticationMethods || []).length <= 1 && u.accountEnabled !== false);
  }, [users]);

  if (isLoadingSecurityScore || isLoadingProfile) {
    return <LoadingContainer />;
  }

  if (!enableCharts && !isAdmin) {
    return (
      <ApplicationViewContainer>
        <div className={classes.disclaimer}>
          <SecurityCard.Badge variant='locked'>
            Please upgrade account. Contact <a href='mailto:accounts@telivy.com'>accounts@telivy.com</a>
          </SecurityCard.Badge>
        </div>
      </ApplicationViewContainer>
    );
  }

  if (!securityScore) {
    return (
      <ApplicationViewContainer>
        <EmptyState title='Microsoft 365 can take anywhere from 15 minutes to a few hours for all the data to be fetched. We will notify you via email when Microsoft 365 insights are ready.' />
      </ApplicationViewContainer>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>Microsoft 365 Security</div>

      <div className={classes.section}>
        <div className={classes.summary}>
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Security Score'
            skipGrading={`${securityScorePercent.toFixed(2)}%`}
            score={securityScorePercent}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Benchmark Score'
            skipGrading={`${benchmarkPercent}%`}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title='Points Achieved'
            skipGrading={`${securityScoreData?.currentScore || 'N/A'} / ${securityScoreData?.maxScore || 'N/A'}`}
          />
          {/*<div className={classes.item}>
            <div className={cx(classes.number, securityScorePercent > benchmarkPercent ? classes.green : classes.red)}>
              {securityScorePercent}%
            </div>
            <div className={classes.description}>Security Score</div>
          </div>
          <div className={classes.item}>
            <div className={classes.number}>{benchmarkPercent}%</div>
            <div className={classes.description}>Benchmark Score</div>
          </div>

          <div className={classes.item}>
            <div className={classes.number}>
              {securityScoreData?.currentScore || 'N/A'} / {securityScoreData?.maxScore || 'N/A'}
            </div>
            <div className={classes.description}>Points Achieved</div>
          </div>*/}
        </div>

        {!auditLogsSignIns?.error && (
          <div className={classes.summary}>
            <ScoreSecurityCard className={classes.securityCard} title='Total Accounts' skipGrading={users?.length} />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Accounts without MFA'
              skipGrading={accountNoMfa.length.toLocaleString()}
              score={accountNoMfa.length > 0 ? 50 : 100}
              onClick={onClickNoMfaCount}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Total Failed Logins'
              skipGrading={(loginAudits || []).filter((l) => l.status.errorCode !== 0).length.toLocaleString()}
              score={(loginAudits || []).filter((l) => l.status.errorCode !== 0).length > 0 ? 50 : 100}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Orphaned Accounts'
              skipGrading={(orphanedAccounts || []).length.toLocaleString()}
              score={(orphanedAccounts || []).length > 0 ? 50 : 100}
            />
            {/*<div className={classes.item}>
              <div className={cx(classes.number, classes.blue)}>{users?.length}</div>
              <div className={classes.description}>Total Accounts</div>
            </div>
            <div className={classes.item}>
              <div>
                <Button variant='text' className={classes.clickButton} onClick={onClickNoMfaCount}>
                  <span className={cx(classes.number, classes.red)}>
                    {
                      (users || []).filter(
                        (u) => (u.authenticationMethods || []).length <= 1 && u.accountEnabled !== false,
                      ).length
                    }
                  </span>
                </Button>
              </div>
              <div className={classes.description}>Accounts without MFA</div>
            </div>
            <div className={classes.item}>
              <div className={cx(classes.number, classes.red)}>
                {(loginAudits || []).filter((l) => l.status.errorCode !== 0).length}
              </div>
              <div className={classes.description}>Total Failed Logins</div>
            </div>
            <div className={classes.item}>
              <div className={classes.number}>{(orphanedAccounts || []).length}</div>
              <div className={classes.description}>Orphaned Accounts</div>
            </div>*/}
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.title}>Security Score History</div>

        {securityScoreChart}
      </div>

      {/*<div className={classes.section}>
        <div className={classes.title}>
          Recommended Actions
          <span className={classes.findings}>
            {' '}
            ({actions.filter((a) => (a.scoreImpact || 0) > 0).length} to address)
          </span>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanM365ControlScore, keyof DeepScanM365ControlScore>
            columns={actionColumns}
            pagination={
              isLoadingSecurityScore || isLoadingSecurityScoreControlProfiles
                ? undefined
                : { page: actionPage, perPage: PER_PAGE, total: sortedActions?.length || 0 }
            }
            sorter={actionSorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setActionPage(pagination?.page);
              }

              const isSorterChanging = actionSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setActionSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setActionPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.controlName}`}
            data={actionsData}
            loading={isLoadingSecurityScore || isLoadingSecurityScoreControlProfiles}
          />
        </div>
      </div>*/}

      <div className={classes.section}>
        <div className={classes.title} id='account-status'>
          Accounts Status
          <span className={classes.findings}>
            <Button
              variant='outlined'
              className={classes.marginRight}
              onClick={() => {
                setShowOnlyNoMfa(!showOnlyNoMfa);
                setUserPage(0);
              }}
            >
              Show {showOnlyNoMfa ? 'All' : 'No MFA'}
            </Button>
            ({usersData.length} users{showOnlyNoMfa ? ' with no MFA' : ''})
          </span>
        </div>
        <div className={classes.boxBorder}>
          <Table<DeepScanM365UserRow, keyof DeepScanM365UserRow>
            columns={userColumns}
            pagination={
              isLoadingUsers ? undefined : { page: userPage, perPage: PER_PAGE, total: sortedUsers?.length || 0 }
            }
            sorter={userSorter}
            onChange={(pagination, sorting) => {
              if (pagination?.page !== undefined) {
                setUserPage(pagination?.page);
              }

              const isSorterChanging = userSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
              setUserSorter(sorting);

              if (isSorterChanging && pagination?.page !== 0) {
                setUserPage(0);
              }
            }}
            rowContentCentered
            className={classes.table}
            rowKey={(row) => `${row.id}`}
            data={usersData}
            loading={isLoadingUsers}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.title}>
          Login Status
          {/*<span className={classes.findings}> ({loginAudits.length} audit logs)</span>*/}
        </div>
        {auditLogsSignIns?.error && auditLogsSignIns?.error.indexOf('premium license') > -1 && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            To get login and security data from M365, an Azure Microsoft Entra ID P1 (NCE) license and Global admin is
            required. This license allows us to retrieve monitor login statistics like location and MFA status from
            Microsoft.
            <br />
            You can check out the plans, costs and features{' '}
            <a
              href='https://www.microsoft.com/en-us/security/business/microsoft-entra-pricing'
              target='_blank'
              rel='noreferrer'
            >
              here
            </a>
            .
          </SecurityCard.Badge>
        )}
        {auditLogsSignIns?.error && isAdmin && (
          <SecurityCard.Badge variant='error' className={classes.errorBox}>
            [Admin] Error: {auditLogsSignIns?.error}
          </SecurityCard.Badge>
        )}
        {loginAudits.length > 0 && (
          <>
            <div className={classes.summary}>
              <ScoreSecurityCard
                className={classes.securityCard}
                title='Total Login Events'
                skipGrading={(loginAudits || []).length.toLocaleString()}
                // score={(loginAudits || []).length > 0 ? 85 : 100}
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Successful Logins'
                skipGrading={loginAudits.filter((l) => l.status.errorCode === 0).length.toLocaleString()}
                score={100}
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Failed Logins'
                skipGrading={loginAudits.filter((l) => l.status.errorCode !== 0).length.toLocaleString()}
                score={loginAudits.filter((l) => l.status.errorCode !== 0).length > 0 ? 50 : 100}
              />

              <ScoreSecurityCard
                className={classes.securityCard}
                title='Login Locations'
                skipGrading={sortedLocations.length.toLocaleString()}
                // score={85}
              />

              {/*<div className={classes.item}>
                <div className={classes.number}>{loginAudits.length}</div>
                <div className={classes.description}>Events</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.green)}>
                  {loginAudits.filter((l) => l.status.errorCode === 0).length}
                </div>
                <div className={classes.description}>Successful Logins</div>
              </div>

              <div className={classes.item}>
                <div className={cx(classes.number, classes.red)}>
                  {loginAudits.filter((l) => l.status.errorCode !== 0).length}
                </div>
                <div className={classes.description}>Failed Logins</div>
              </div>

              <div className={classes.item}>
                <div className={classes.number}>{sortedLocations.length}</div>
                <div className={classes.description}>Login Locations</div>
              </div>*/}
            </div>

            <div className={cx(classes.mapContainer, classes.section)}>
              <div className={classes.mapContainerItem}>
                {mapChart}
                <div className={classes.legend}>
                  <strong>Legend: </strong>
                  <Badge variant='green'>Low Severity</Badge>
                  <Badge variant='yellow'>Medium Severity</Badge>
                  <Badge variant='red'>High Severity</Badge>
                </div>
              </div>

              <h3>Login Locations</h3>
              <div className={classes.mapContainerItem}>
                <Table<M365LoginByLocationData, keyof M365LoginByLocationData>
                  columns={locationColumns}
                  pagination={
                    isLoadingAuditLogsSignIns
                      ? undefined
                      : { page: locationPage, perPage: PER_PAGE_LOCATIONS, total: sortedLocations?.length || 0 }
                  }
                  sorter={locationSorter}
                  onChange={(pagination, sorting) => {
                    if (pagination?.page !== undefined) {
                      setLocationPage(pagination?.page);
                    }

                    const isSorterChanging = locationSorter?.key !== sorting?.key || sorting?.order !== sorting?.order;
                    setLocationSorter(sorting);

                    if (isSorterChanging && pagination?.page !== 0) {
                      setLocationPage(0);
                    }
                  }}
                  rowContentCentered
                  className={classes.table}
                  rowKey={(row) => `${row.location}`}
                  data={locationsData}
                  loading={isLoadingAuditLogsSignIns}
                />
              </div>

              <h3>Login Failure Reasons</h3>
              <div className={classes.mapContainerItem}>
                <Table<M365LoginByFailureData, keyof M365LoginByFailureData>
                  columns={failuresColumns}
                  pagination={
                    isLoadingAuditLogsSignIns
                      ? undefined
                      : { page: failuresPage, perPage: PER_PAGE_LOCATIONS, total: sortedFailureReasons?.length || 0 }
                  }
                  // sorter={locationSorter}
                  onChange={(pagination) => {
                    if (pagination?.page !== undefined) {
                      setFailuresPage(pagination?.page);
                    }
                  }}
                  rowContentCentered
                  className={classes.table}
                  rowKey={(row) => `${row.reason}:${row.user}`}
                  data={failreReasonsData}
                  loading={isLoadingAuditLogsSignIns}
                />
              </div>
            </div>
            <div className={classes.section}>{failedLoginsChart}</div>
            <div className={classes.section}>{loginActivityChart}</div>
          </>
        )}
      </div>

      {profile?.subscribedSkus?.value && (
        <div className={classes.section}>
          <div className={classes.title}>License Information</div>
          <div className={classes.boxBorder}>
            <Table<DeepScanM365SubscribedSku, keyof DeepScanM365SubscribedSku>
              columns={subscribedSkuColumns}
              rowContentCentered
              className={classes.table}
              rowKey={(row) => `${row.id}`}
              data={subscribedSkuData}
              loading={isLoadingProfile}
            />

            <SubscribedSkuDetailsModal
              subscribedSku={selectedSubscribedSku}
              isOpen={Boolean(selectedSubscribedSku)}
              handleClose={handleClose}
            />
          </div>
        </div>
      )}
    </div>
  );
};
