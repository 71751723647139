import { UISchemaElement } from '@jsonforms/core';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { JsonForms } from 'components/JsonForms';
import { Step } from 'components/Step';
import { diff } from 'deep-object-diff';
import { ApplicationDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { isArray } from 'lodash';
import intersection from 'lodash/intersection';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import { useEditApplication } from 'queries/useApplications';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { isSecurityApplication } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { ExportApplicationButton } from './ExportApplicationButton';
import { ShareApplicationButton } from './ShareApplicationButton';

function objectDeepKeys(obj: any): string[] {
  return Object.keys(obj)
    .filter((key) => isObject(obj[key]))
    .map((key) => objectDeepKeys(obj[key]).map((k) => `${key}.${k}`))
    .reduce(
      (x, y) => x.concat(y),
      Object.keys(obj).filter((key) => !isObject(obj[key])),
    );
}

function getNestedObjectKeysCount(data: any): number {
  let sum = 0;
  for (const key in data) {
    if (isObject(data[key])) {
      sum += getNestedObjectKeysCount(data[key]);
    } else {
      sum += 1;
    }
  }

  return sum;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  sidebar: {
    width: 270,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  content: {
    flex: 1,
    paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
  },
  actionButton: {
    minWidth: theme.spacing(12),
  },
  steps: {},
  button: {
    '& + $button': {
      marginLeft: theme.spacing(2),
    },
  },
  buttonIcon: {
    border: 'none',
    minWidth: 0,
    padding: `0 ${theme.spacing()}px`,
  },
  changesCount: {
    borderRadius: theme.spacing(),
    backgroundColor: COLORS.YELLOW_2,
    padding: `0 ${theme.spacing()}px`,
    color: COLORS.BLACK,
    marginLeft: theme.spacing(),
  },
  actionsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    width: 1,
    backgroundColor: COLORS.GREY_5,
    height: 'auto',
    margin: `${theme.spacing(0.5)}px 0`,
  },
  monitoringFormContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  monitoringForm: {
    alignSelf: 'end',
    marginLeft: theme.spacing(1),
  },
}));

interface QueryParams {
  section?: string;
}

interface Props {
  application: ApplicationDTO;
}

export const ApplicationFormView = ({ application }: Props) => {
  const classes = useStyles();
  const { search } = useLocation();
  const [activePage, setActivePage] = useState('general');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorPaths, setErrorPaths] = useState<string[]>([]);
  const [applicationData, setApplicationData] = useState<any>({});
  const [applicationEditedData, setApplicationEditedData] = useState<any>({});
  const hasLoadedInitialData = useRef(false);
  const { showToaster, toasterErrorHandler } = useToasters();
  const {
    applicationResponse,
    applicationVersion: { dataSchema, uiSchema },
  } = application;

  const queryParams = useMemo(() => queryString.parse(search) as unknown as QueryParams, [search]);

  const [seenSectionsIds, setSeenSectionsIds] = useState<number[]>([]);

  useEffect(() => {
    const seenSectionsIdxFromStorage = localStorage.getItem(`assessment-${application.id}`);
    const parsedSeenSections = seenSectionsIdxFromStorage ? JSON.parse(seenSectionsIdxFromStorage) : [];

    if (isArray(parsedSeenSections) && parsedSeenSections?.length > 0) {
      setSeenSectionsIds(parsedSeenSections);
    }
  }, [application.id]);

  useEffect(() => {
    // We want to invoke this code only once on initial load
    if (applicationResponse && !hasLoadedInitialData.current) {
      setApplicationData(applicationResponse);
      setApplicationEditedData(applicationResponse);
      hasLoadedInitialData.current = true;
    }
  }, [applicationResponse, applicationData, hasLoadedInitialData]);

  useEffect(() => {
    const sections = uiSchema.sections.map((section) => section.pageSlug);

    if (queryParams.section && sections.includes(queryParams.section)) setActivePage(queryParams.section);
  }, [queryParams.section, uiSchema]);

  const { mutate, isLoading: isSaving } = useEditApplication(application.id, {
    onSuccess: (data) => {
      showToaster('Changes were successfully saved');
      setApplicationEditedData(data.applicationResponse);
      setApplicationData(data.applicationResponse);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const currentPageIdx = useMemo(() => {
    const activePageIdx = uiSchema.sections.findIndex((el) => el.pageSlug === activePage);

    if (activePageIdx >= 0) {
      return activePageIdx;
    }

    return null;
  }, [activePage, uiSchema]);
  const currentPageData = currentPageIdx !== null ? uiSchema.sections[currentPageIdx] : null;

  const handleUpdateData = useCallback(
    (updatedData: any) => {
      setApplicationEditedData(updatedData);
    },
    [setApplicationEditedData],
  );

  const goToNextPage = useCallback(() => {
    if (isNumber(currentPageIdx)) {
      setActivePage(uiSchema.sections[currentPageIdx + 1].pageSlug);

      setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
    }
  }, [currentPageIdx, uiSchema.sections]);

  const goToPreviousPage = useCallback(() => {
    if (isNumber(currentPageIdx)) {
      setActivePage(uiSchema.sections[currentPageIdx - 1].pageSlug);

      setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
    }
  }, [currentPageIdx, uiSchema.sections]);

  const isLastPage = useMemo(() => currentPageIdx === uiSchema.sections.length - 1, [uiSchema, currentPageIdx]);
  const isFirstPage = useMemo(() => currentPageIdx === 0, [currentPageIdx]);

  const handleSave = useCallback(async () => {
    await mutate({
      applicationResponse: applicationEditedData,
    });
    localStorage.setItem(`assessment-${application.id}`, JSON.stringify(seenSectionsIds));
  }, [application.id, applicationEditedData, mutate, seenSectionsIds]);

  const changesCount = getNestedObjectKeysCount(diff(applicationData, applicationEditedData));
  const changesKeys = objectDeepKeys(diff(applicationData, applicationEditedData));

  // const redirectToFirstSectionWithErrors = useCallback(() => {
  //   // if there are any errors on the previous pages, then redirect to the first page with errors
  //   if (application) {
  //     const allPages = application.applicationVersion.uiSchema.sections;
  //     // all pages before the current one reduced into array of objects
  //     const allPagesReduced = map(allPages, (element) => ({
  //       // paths is an array with paths to field on current page
  //       paths: element.uiSchema.propertiesPaths,
  //       pageSlug: element.pageSlug,
  //     }));
  //     // all errors in the whole form
  //     const parsedPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
  //     const firstSectionWithErrors = allPagesReduced.find((page) => {
  //       return intersection(page.paths, parsedPaths).length !== 0;
  //     });
  //
  //     if (firstSectionWithErrors) {
  //       setActivePage(firstSectionWithErrors.pageSlug);
  //     }
  //   }
  // }, [application, errorPaths]);

  return (
    <ApplicationViewContainer
      title='Questionnaire'
      actions={
        <>
          <ShareApplicationButton
            applicationId={application.id}
            className={classes.monitoringForm}
            skipBillingSettings={isSecurityApplication(application)}
          />
          {!isSecurityApplication(application) && (
            <ExportApplicationButton
              handleSave={handleSave}
              shouldSave={changesCount > 0}
              isFormValid={isFormValid}
              applicationId={application.id}
              className={classes.buttonIcon}
            />
          )}
          <Button disabled={!changesCount || isSaving} className={classes.actionButton} onClick={handleSave}>
            {isSaving ? (
              'Saving...'
            ) : (
              <>Save Changes {changesCount > 0 && <span className={classes.changesCount}>{changesCount}</span>}</>
            )}
          </Button>
        </>
      }
    >
      <div className={classes.root}>
        <Helmet>
          <title>Questionnaire - {application?.applicationResponse?.organization_name}</title>
        </Helmet>
        <div className={classes.sidebar}>
          <div className={classes.steps}>
            {uiSchema.sections.map((data, index) => {
              const { propertiesPaths } = data.uiSchema;
              const parsedPaths = errorPaths.map((p) => p.replace(/\.\d+\./, '.')); // handle arrays
              const errors = intersection(propertiesPaths, parsedPaths);
              const changesCount = intersection(propertiesPaths, changesKeys);
              const hasErrors = errors.length !== 0;

              return (
                <Step
                  key={index}
                  changesCount={changesCount.length}
                  idx={index}
                  currIndex={currentPageIdx || 0}
                  title={data.label}
                  isActive={index === currentPageIdx}
                  description={
                    hasErrors
                      ? `${errors.length} incomplete question${errors.length > 1 ? 's' : ''}`
                      : `${data.questionsCount} question${data.questionsCount > 1 ? 's' : ''}`
                  }
                  progress={0}
                  hasBeenSeen={seenSectionsIds.includes(index)}
                  isError={hasErrors}
                  totalQuestions={2}
                  onClick={() => {
                    setActivePage(data.pageSlug);
                    currentPageIdx != null && setSeenSectionsIds((prev) => uniq([...prev, currentPageIdx]));
                  }}
                />
              );
            })}
          </div>
        </div>

        <main className={classes.content}>
          <Box m={1}>
            {currentPageData && (
              <JsonForms
                schema={dataSchema}
                uischema={currentPageData.uiSchema as UISchemaElement} // JSON Forms typings sucks...
                data={applicationEditedData}
                onChange={({ data, errors }) => {
                  handleUpdateData(data);
                  setIsFormValid(errors ? errors.length === 0 : false);
                  setErrorPaths(uniq((errors || []).map((e) => e.dataPath)));
                }}
                validationMode='ValidateAndShow'
                applicationSource='agent'
              />
            )}
          </Box>
          <div className={classes.actionsContainer}>
            {!isFirstPage && (
              <Button size='large' variant='outlined' onClick={goToPreviousPage} className={classes.button}>
                Back
              </Button>
            )}

            {!isLastPage && (
              <Button
                size='large'
                variant='outlined'
                onClick={goToNextPage}
                className={classes.button}
                style={{
                  marginLeft: isFirstPage ? 'auto' : 'none',
                }}
              >
                Next
              </Button>
            )}
          </div>
        </main>
      </div>
    </ApplicationViewContainer>
  );
};
