import { Button as MuiButton, ButtonGroup, CircularProgress, makeStyles, Tooltip, withStyles } from '@material-ui/core';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { EmptyState } from 'components/EmptyState';
import { Modal } from 'components/Modal';
import { Option, RadioSelect } from 'components/RadioSelect';
import {
  ApplicationStatus,
  ApplicationWithAgentDTO,
  MonitoringSnapshotDTO,
  SecurityGrade,
  SecurityScanDTO,
  SecurityScanStatus,
} from 'dtos/application';
import { DeepScanDTO } from 'dtos/deep-scan';
import { startCase } from 'lodash';
import { useCurrentAgent, useTrackAgentEvent } from 'queries/useAgent';
import { useGenerateExternalScanReport } from 'queries/useApplications';
import React, { useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { createSecurityRoute } from 'telivy-constants';
import { isAgentAnAdmin, isScanApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

import { DarkWebPresenceChart } from './components/DarkWebPresenceChart';
import { DeepScanApplicationSecurity } from './views/deep-scan/ApplicationSecurity';
import { DeepScanDataSecurity } from './views/deep-scan/DataSecurity';
import { DeepScanDataSecurityPiiTools } from './views/deep-scan/DataSecurityPiiTools';
import { DeepScanGoogleWsSecurity } from './views/deep-scan/GoogleWsSecurity';
import { DeepScanIdentityAccessManagement } from './views/deep-scan/IdentityAccessManagement';
import { DeepScanM365Security } from './views/deep-scan/M365Security';
import { DeepScanNetworkSecurity } from './views/deep-scan/NetworkSecurity';
import { SecurityRiskAnalysis } from './views/deep-scan/RiskAnalysis';
import { DeepScanSecurityAwarenessTraining } from './views/deep-scan/SecurityAwarenessTraining';
import { SecurityCompanyInfo } from './views/SecurityCompanyInfo';
import { SecurityDarkWeb } from './views/SecurityDarkWeb';
import { SecurityDownload } from './views/SecurityDownload';
import { SecurityExternalScan } from './views/SecurityExternalScan';
import { SecurityFinding } from './views/SecurityFinding';
import { SecurityFindings } from './views/SecurityFindings';
import { SecurityOverview } from './views/SecurityOverview';
import { SecurityPrintView } from './views/SecurityPrintView';
import { SecurityTargets } from './views/SecurityTargets';
import { SecurityTechStack } from './views/SecurityTechStack';

const CustomButtonGroup = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    // border: `1px solid ${COLORS.GREY_5}`,
    // borderRadius: `${theme.spacing()}px !important`,
    // padding: `0 ${theme.spacing()}px`,
    position: 'relative',
    minHeight: theme.spacing(4.25),
    // overflow: 'auto',

    // '&:hover, &:focus': {
    //   backgroundColor: COLORS.BLUE_3,
    //   borderColor: 'transparent',

    //   '& > button': {
    //     color: COLORS.BLUE_1,
    //   },
    // },
  },
  // groupedContainedHorizontal: {
  //   borderRight: 'none !important',
  // },
}))(ButtonGroup);

const Button = withStyles((theme) => ({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    '&.groupFirst': {
      borderRadius: `${theme.spacing()}px 0 0 ${theme.spacing()}px !important`,
    },
    '&.groupSecond': {
      borderRadius: `0 ${theme.spacing()}px ${theme.spacing()}px 0 !important`,
    },
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  root: {},

  menu: {
    display: 'flex',
    gap: theme.spacing(2.5),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  menuButtons: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  select: {
    marginBottom: theme.spacing(2),
  },
  lastScanned: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: COLORS.GREY_2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  scanning: {
    marginRight: theme.spacing(1),
  },
  viewLatest: {
    marginLeft: theme.spacing(1),
  },
  historyButton: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  activeHistoryButton: {
    color: COLORS.BLUE_1,
  },
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    FINDING_ROOT: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    VULNERABILITIES: string;
    DATA_LIABILITY: string;
    RISK_ANALYSIS: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
    DARK_WEB: string;
    deepScan: {
      NETWORK_SECURITY: string;
      APPLICATION_SECURITY: string;
      DATA_SECURITY: string;
      DATA_SECURITY_PII_TOOLS: string;
      IDENTITY_ACCESS_MANAGEMENT: string;
      DARK_WEB_PRESENCE: string;
      M365_SECURITY: string;
      GWS_SECURITY: string;
      ROOT: string;
    };
    ROOT: string;
  };
  HISTORY?: string;
  ROOT: string;
  FORM: string;
  QUOTES?: string;
  PROPOSAL?: string;
  DOCUMENTS?: string;
  POLICIES?: string;
};

// interface ParamsType {
//   securityScanId: string;
// }

interface Props {
  securityScan: SecurityScanDTO;
  applicationId: string;
  application: ApplicationWithAgentDTO;
  deepScan?: DeepScanDTO;
  onViewQuestionnaireClick: () => void;
  sourceRoute: SourceRoute;
  monitoringSnapshots?: MonitoringSnapshotDTO[];
  selectedAssets: string[];
  setSelectedAssets: (a: string[]) => void;
  allAssets: string[];
  selectedAccounts: string[];
  setSelectedAccounts: (a: string[]) => void;
  allAccounts: string[];
  selectedLimit: string;
  setSelectedLimit: (a: string) => void;
}

const ReportButton = (props: {
  type: 'detailed' | 'executive';
  hasExternalScansCompleted: boolean;
  agentId: string;
  appId: string;
  orgName: string;
}) => {
  const [open, setOpen] = useState<string | null>(null);
  const { mutate: downloadReport, isLoading: isGenerating } = useGenerateExternalScanReport(props.appId);
  const { mutate } = useTrackAgentEvent(props.agentId);
  const capitalType = startCase(props.type);
  const handleReportGeneration = React.useCallback(
    (format: 'pdf' | 'docx', force?: boolean) => {
      if (!props.hasExternalScansCompleted && !force) {
        setOpen(format);
        return;
      }

      setOpen(null);
      mutate({ event: `download${capitalType}SecurityScanReport` });
      downloadReport({ type: props.type, orgName: props.orgName, format });
    },
    [capitalType, downloadReport, mutate, props.hasExternalScansCompleted, props.orgName, props.type],
  );

  return (
    <>
      <Tooltip
        title={
          props.hasExternalScansCompleted
            ? `Click to download External Scan ${capitalType} Report`
            : 'External scan is not completed yet'
        }
      >
        <CustomButtonGroup
          variant={props.type == 'executive' ? 'outlined' : 'outlined'}
          color={props.type == 'executive' ? undefined : undefined}
          aria-label='split button'
        >
          <Button
            onClick={() => handleReportGeneration('pdf', false)}
            disabled={isGenerating}
            style={{ marginLeft: 'auto' }}
            variant={props.type == 'executive' ? 'outlined' : 'outlined'}
            color={props.type == 'executive' ? undefined : undefined}
            className='groupFirst'
            startIcon={<CloudDownloadRoundedIcon />}
          >
            {!props.hasExternalScansCompleted && <CircularProgress size={20} style={{ marginRight: 12 }} />}
            {isGenerating ? 'Generating...' : `${capitalType} Report PDF`}
          </Button>
          <Button
            onClick={() => handleReportGeneration('docx', false)}
            disabled={isGenerating}
            variant={props.type == 'executive' ? 'outlined' : 'outlined'}
            color={props.type == 'executive' ? undefined : undefined}
            className='groupSecond'
          >
            {isGenerating ? '...' : `DOCX`}
          </Button>
        </CustomButtonGroup>
      </Tooltip>
      <Modal
        handleClose={() => setOpen(null)}
        isOpen={!!open}
        title='Scanning in Progress'
        actions={
          <>
            <Button variant='contained' color='primary' onClick={() => setOpen(null)}>
              Cancel Download
            </Button>
            <Button variant='contained' onClick={() => handleReportGeneration(open === 'docx' ? 'docx' : 'pdf', true)}>
              I understand, download anyway
            </Button>
          </>
        }
      >
        <p>
          We haven&apos;t finished scanning your application. If you download the report now, it will be incomplete.
        </p>
      </Modal>
    </>
  );
};

export const SecurityAssessments = ({
  application,
  securityScan,
  deepScan,
  onViewQuestionnaireClick,
  applicationId,
  sourceRoute,
  monitoringSnapshots,
  selectedAssets,
  setSelectedAssets,
  allAssets,
  selectedAccounts,
  setSelectedAccounts,
  allAccounts,
  selectedLimit,
  setSelectedLimit,
}: Props) => {
  const { data } = useCurrentAgent();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { securityScanId } = useParams();
  const { applicationStatus, applicationResponse } = application;
  const orgName = applicationResponse.organization_name;

  const viewOptions: Option<ValueOf<SourceRoute['security'] | SourceRoute['security']['deepScan']>>[] =
    React.useMemo(() => {
      const options = [
        // {
        //   label: 'Overview',
        //   value: createSecurityRoute(sourceRoute.security.OVERVIEW, applicationId, securityScanId),
        // },
      ];

      // if (!isScanApplication(application)) {
      //   const allDomains = [securityScan.domain]
      //     .concat(securityScan.subDomains || [], securityScan.ipAddresses || [], securityScan.otherDomains || [])
      //     .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1);

      //   options.push({
      //     label: `Targets (${uniq(allDomains).length})`,
      //     value: createSecurityRoute(sourceRoute.security.TARGETS, applicationId, securityScanId),
      //   });
      // }

      if (isScanApplication(application)) {
        // if (isAgentAnAdmin(data)) {
        //   options.push({
        //     label: 'Human Risk',
        //     value: createSecurityRoute(sourceRoute.security.RISK_ANALYSIS, applicationId, securityScanId),
        //   });
        // }
        options.push({
          label: 'Network',
          value: createSecurityRoute(sourceRoute.security.deepScan.NETWORK_SECURITY, applicationId, securityScanId),
        });
        options.push({
          label: 'Application',
          value: createSecurityRoute(sourceRoute.security.deepScan.APPLICATION_SECURITY, applicationId, securityScanId),
        });
        options.push({
          label: 'Data',
          value: createSecurityRoute(sourceRoute.security.deepScan.DATA_SECURITY, applicationId, securityScanId),
        });

        // if (isAgentAnAdmin(data)) {
        //   options.push({
        //     label: 'Data Security (PII Tools)',
        //     value: createSecurityRoute(
        //       sourceRoute.security.deepScan.DATA_SECURITY_PII_TOOLS,
        //       applicationId,
        //       securityScanId,
        //     ),
        //   });
        // }

        options.push({
          label: 'Identity & Access',
          value: createSecurityRoute(
            sourceRoute.security.deepScan.IDENTITY_ACCESS_MANAGEMENT,
            applicationId,
            securityScanId,
          ),
        });
        options.push({
          label: 'Dark Web',
          value: createSecurityRoute(sourceRoute.security.deepScan.DARK_WEB_PRESENCE, applicationId, securityScanId),
        });

        if (deepScan?.m365Connected) {
          options.push({
            label: 'Microsoft 365',
            value: createSecurityRoute(sourceRoute.security.deepScan.M365_SECURITY, applicationId, securityScanId),
          });
        }

        if (deepScan?.gwsConnected) {
          options.push({
            label: 'Google Workspace',
            value: createSecurityRoute(sourceRoute.security.deepScan.GWS_SECURITY, applicationId, securityScanId),
          });
        }
      } else {
        options.push({
          label: 'External Scan',
          value: createSecurityRoute(sourceRoute.security.EXTERNAL_SCAN, applicationId, securityScanId),
        });
        options.push({
          label: 'Findings',
          value: createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScanId, ''),
        });
        options.push({
          label: 'Dark Web',
          value: createSecurityRoute(sourceRoute.security.DARK_WEB, applicationId, securityScanId),
        });
        // options.push({
        //   label: 'Tech Stack',
        //   value: createSecurityRoute(sourceRoute.security.TECHSTACK, applicationId, securityScanId),
        // });
        // options.push({
        //   label: 'Company Info',
        //   value: createSecurityRoute(sourceRoute.security.COMPANY_INFO, applicationId, securityScanId),
        // });
      }

      return options;
    }, [applicationId, securityScanId, sourceRoute, application, deepScan]);

  // print
  const isNotSubmitted =
    !applicationStatus || [ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS].includes(applicationStatus);

  const componentRef = React.useRef(null);
  const printButtonRef = React.useRef(null);

  const hasExternalScansCompleted = securityScan.status === SecurityScanStatus.COMPLETED;

  const triggerPrint = () => {
    if (securityScan.status != SecurityScanStatus.COMPLETED) {
      if (printButtonRef.current) {
        (printButtonRef.current as any).click();
        navigate(createSecurityRoute(sourceRoute.security.OVERVIEW, applicationId, securityScanId));
      }
    }
  };

  return (
    <div>
      {!securityScan.securityStats?.preview ? (
        <EmptyState title='Not Filled' text='The assessment form has not been filled.' icon={<LockOutlinedIcon />} />
      ) : (
        <>
          <div style={{ display: 'none' }}>
            <SecurityPrintView
              application={application}
              isNotSubmitted={isNotSubmitted}
              applicationId={applicationId}
              securityScan={securityScan}
              ref={componentRef}
            />
          </div>
          <div className={classes.menu}>
            <div>
              <RadioSelect<ValueOf<SourceRoute['security'] | SourceRoute['security']['deepScan']>>
                responsive
                id='views-menu'
                options={viewOptions}
                className={classes.select}
                selectedValue={location.pathname}
                onOptionSelect={(opt) => navigate(opt.value)}
                selectedFn={(optionValue, selectedValue) =>
                  selectedValue ? selectedValue.includes(optionValue) : false
                }
              />
            </div>
            {!isScanApplication(application) && (
              <div className={classes.menuButtons}>
                <ReportButton
                  hasExternalScansCompleted={hasExternalScansCompleted}
                  appId={applicationId}
                  agentId={application.agent.id}
                  type='executive'
                  orgName={orgName}
                />
                <ReportButton
                  hasExternalScansCompleted={hasExternalScansCompleted}
                  appId={applicationId}
                  agentId={application.agent.id}
                  type='detailed'
                  orgName={orgName}
                />
              </div>
            )}
          </div>
          <Routes>
            <Route
              path={sourceRoute.security.OVERVIEW}
              element={
                <SecurityOverview
                  onViewQuestionnaireClick={onViewQuestionnaireClick}
                  securityScan={securityScan}
                  applicationId={applicationId}
                  isNotSubmitted={isNotSubmitted}
                  handleOpenExternalScan={() =>
                    navigate(createSecurityRoute(sourceRoute.security.EXTERNAL_SCAN, applicationId, securityScanId))
                  }
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                  application={application}
                />
              }
            />
            <Route
              path={sourceRoute.security.TARGETS}
              element={
                <SecurityTargets
                  securityScan={securityScan}
                  securityScanId={securityScanId || ''}
                  applicationId={applicationId}
                  application={application}
                  sourceRoute={sourceRoute}
                />
              }
            />
            <Route
              path={sourceRoute.security.EXTERNAL_SCAN}
              element={
                <SecurityExternalScan
                  securityScan={securityScan}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                />
              }
            />
            <Route
              path={sourceRoute.security.FINDINGS}
              element={
                <SecurityFindings
                  securityScan={securityScan}
                  securityScanId={securityScanId || ''}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                />
              }
            />
            <Route
              path={sourceRoute.security.FINDING_ROOT}
              element={
                <SecurityFinding
                  securityScan={securityScan}
                  securityScanId={securityScanId || ''}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                />
              }
            />
            <Route
              path={sourceRoute.security.RISK_ANALYSIS}
              element={<SecurityRiskAnalysis application={application} />}
            />
            <Route path={sourceRoute.security.TECHSTACK} element={<SecurityTechStack application={application} />} />
            <Route
              path={sourceRoute.security.COMPANY_INFO}
              element={<SecurityCompanyInfo applicationId={applicationId} application={application} />}
            />
            <Route
              path={sourceRoute.security.DARK_WEB}
              element={
                <>
                  <DarkWebPresenceChart
                    securityScan={securityScan}
                    findingsUrl={createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScan.id, '')}
                    applicationId={applicationId}
                  />
                  <SecurityDarkWeb
                    applicationId={applicationId}
                    enableDarkWebFullPassword={application.enableDarkWebFullPassword}
                  />
                </>
              }
            />
            <Route
              path={sourceRoute.security.DOWNLOAD}
              element={
                <SecurityDownload
                  triggerDownload={triggerPrint}
                  loading={securityScan.status != SecurityScanStatus.COMPLETED}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.NETWORK_SECURITY}
              element={
                <DeepScanNetworkSecurity
                  securityScan={securityScan}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                  application={application}
                  monitoringSnapshots={monitoringSnapshots}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  allAssets={allAssets}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.APPLICATION_SECURITY}
              element={
                <DeepScanApplicationSecurity
                  securityScan={securityScan}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                  application={application}
                  isAdmin={isAgentAnAdmin(data)}
                  monitoringSnapshots={monitoringSnapshots}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.DATA_SECURITY}
              element={
                <DeepScanDataSecurity
                  application={application}
                  isAdmin={isAgentAnAdmin(data)}
                  monitoringSnapshots={monitoringSnapshots}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  allAssets={allAssets}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.DATA_SECURITY_PII_TOOLS}
              element={<DeepScanDataSecurityPiiTools application={application} />}
            />
            <Route
              path={sourceRoute.security.deepScan.IDENTITY_ACCESS_MANAGEMENT}
              element={
                <DeepScanIdentityAccessManagement
                  securityScan={securityScan}
                  applicationId={applicationId}
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                  application={application}
                  monitoringSnapshots={monitoringSnapshots}
                  selectedAssets={selectedAssets}
                  setSelectedAssets={setSelectedAssets}
                  allAssets={allAssets}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.DARK_WEB_PRESENCE}
              element={
                <DeepScanSecurityAwarenessTraining
                  securityScan={securityScan}
                  applicationId={applicationId}
                  enableDarkWebFullPassword={application.enableDarkWebFullPassword}
                  sourceRoute={sourceRoute}
                  securityScanId={securityScanId || ''}
                  isAdmin={isAgentAnAdmin(data)}
                  application={application}
                  monitoringSnapshots={monitoringSnapshots}
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  allAccounts={allAccounts}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                />
              }
            />
            <Route
              path={sourceRoute.security.deepScan.M365_SECURITY}
              element={<DeepScanM365Security application={application} isAdmin={isAgentAnAdmin(data)} />}
            />
            <Route
              path={sourceRoute.security.deepScan.GWS_SECURITY}
              element={<DeepScanGoogleWsSecurity application={application} isAdmin={isAgentAnAdmin(data)} />}
            />
            <Route
              index
              element={
                <Navigate
                  replace
                  to={createSecurityRoute(
                    isScanApplication(application)
                      ? sourceRoute.security.deepScan.NETWORK_SECURITY
                      : sourceRoute.security.EXTERNAL_SCAN,
                    applicationId,
                    securityScanId,
                  )}
                />
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
};

export const parseScoreToGrade = (value?: number | null) => {
  if (value) {
    if (value > 90) {
      return SecurityGrade.A;
    } else if (value > 80) {
      return SecurityGrade.B;
    } else if (value > 70) {
      return SecurityGrade.C;
    } else if (value > 60) {
      return SecurityGrade.D;
    } else {
      return SecurityGrade.E;
    }
  }

  return SecurityGrade.NA;
};
