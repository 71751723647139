import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import { Column, Table } from 'components/Table';
import { AgentDTO } from 'dtos/agent';
import {
  ALERT_CATEGORIES,
  ALERT_CONDITIONS,
  ALERT_METRIC_LABELS,
  AlertCategory,
  AlertCondition,
  AlertPreference,
} from 'dtos/alert';
import { useToasters } from 'hooks/useToasters';
import { useCurrentAgencyUsers } from 'queries/useAgency';
import { useEditAlertPreferences } from 'queries/useAlerts';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
import { TYPOGRAPHY } from 'telivy-theme';

import { EditAlertPreference } from './EditAlertPreference';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },
  title: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    ...TYPOGRAPHY.LARGE,
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(),
  },
  subTitle: {
    margin: '50px 15px 10px 8px',
  },
  headerRow: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  edit: {
    paddingTop: theme.spacing(0.05),
    paddingBottom: theme.spacing(0.05),
    marginRight: theme.spacing(1),
  },
  boxBorderPadding: {
    border: 'solid 1px #efefef',
    padding: theme.spacing(2),
    width: '50%',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  bordered: {
    border: 'solid 1px #ededed',
  },
  centerButton: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '75vh',
  },
}));

interface AllAlertPreference {
  category: AlertCategory;
  condition: AlertCondition;
  notifyUsers: AgentDTO[];
  preferences: AlertPreference[];
}

export const AlertsPreferencesView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedAlertPreference, setSelectedAlertPreference] = useState<AlertPreference | null>(null);
  const [selectedAlertCategory, setSelectedAlertCategory] = useState<AlertCategory | null>(null);

  const { showToaster, toasterErrorHandler } = useToasters();

  const { data: allUsers } = useCurrentAgencyUsers();
  const { mutateAsync, isLoading } = useEditAlertPreferences({
    onError: (e) => toasterErrorHandler(e),
  });

  const data = useMemo(() => {
    return ([] as AlertPreference[]).concat(...(allUsers || []).map((agent) => agent.alertPreferences || []));
  }, [allUsers]);

  const allColumns: Column<AllAlertPreference, keyof AllAlertPreference>[] = useMemo(() => {
    const baseColumns: Column<AllAlertPreference, keyof AllAlertPreference>[] = [
      {
        title: 'When',
        width: '50%',
        render: (row) =>
          `${ALERT_CATEGORIES[row.category]} ${
            row.condition && `${ALERT_METRIC_LABELS[row.condition?.metric]} ${row.condition?.value}`
          }`,
      },
      {
        title: `Users to Notify`,
        width: '20%',
        render: (row) => {
          if (row.notifyUsers.length == allUsers?.length) {
            return 'All';
          } else if (row.notifyUsers.length === 0) {
            return 'None';
          } else {
            return `${row.notifyUsers.length} / ${allUsers?.length}`;
          }
        },
      },
    ];

    baseColumns.push({
      title: 'Actions',
      width: '30%',
      render: (row) => (
        <>
          <Button
            variant='outlined'
            color='default'
            onClick={() => setSelectedAlertCategory(row.category)}
            className={classes.edit}
          >
            Edit
          </Button>

          {/*<Button*/}
          {/*  variant='outlined'*/}
          {/*  color='default'*/}
          {/*  onClick={() => disableAlertCategory(row.category)}*/}
          {/*  className={classes.edit}*/}
          {/*>*/}
          {/*  Disable*/}
          {/*</Button>*/}
        </>
      ),
    });

    return baseColumns;
  }, [classes, allUsers]);

  const allPrefs: AllAlertPreference[] = useMemo(() => {
    return Object.values(AlertCategory).map((category) => {
      const preferences = ([] as AlertPreference[]).concat(
        ...(allUsers || []).map((agent) => (agent.alertPreferences || []).filter((p) => p.category === category)),
      );
      const notifyUsers = (allUsers || []).filter(
        (agent) => (agent.alertPreferences || []).filter((p) => p.category === category && p.email).length > 0,
      );

      return {
        category,
        condition: ALERT_CONDITIONS[category],
        preferences,
        notifyUsers,
      } as AllAlertPreference;
    });
  }, [allUsers]);

  const addAlertPreferenceAndEdit = useCallback(() => {
    const pref = {
      id: 'id' + new Date().getTime(),
      agentId: 'new',
      category: AlertCategory.INTERNAL_VULNERABILITIES,
      condition: ALERT_CONDITIONS[AlertCategory.INTERNAL_VULNERABILITIES],
      email: false,
      text: false,
    } as AlertPreference;

    setSelectedAlertPreference(pref);
  }, []);

  const addAllAlertPreferences = useCallback(async () => {
    for (const user of allUsers || []) {
      await mutateAsync({
        id: user.id,
        alertPreferences: Object.values(AlertCategory).map((category, index) => {
          return {
            id: 'id' + new Date().getTime() + index.toString(),
            agentId: user.id,
            category,
            condition: ALERT_CONDITIONS[category],
            email: true,
            text: false,
          } as AlertPreference;
        }),
      });

      showToaster(`Successfully updated alert policies for ${user.firstName} ${user.lastName} (${user.email})`);
    }

    showToaster(`Successfully updated all alert policies for all users`);
  }, [mutateAsync, allUsers, showToaster]);

  const handleViewAllAlerts = useCallback(
    () => navigate(`${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}/${ROUTES.agent.alerts.HISTORY}`),
    [navigate],
  );

  if (isLoading) {
    return (
      <div className='mt-8 flex flex-col justify-center items-center'>
        <CircularProgress />
        <p>Configuring...</p>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.actionsContainer}>
          <Typography variant='h2' className={classes.title}>
            Alerts Policies
          </Typography>

          <div>
            <Button
              variant='outlined'
              color='default'
              startIcon={<ChevronLeftRoundedIcon />}
              onClick={() => handleViewAllAlerts()}
              className={classes.actionButton}
            >
              All Alerts
            </Button>

            <Button
              variant='contained'
              color='primary'
              startIcon={<PlaylistAddRoundedIcon />}
              onClick={() => addAlertPreferenceAndEdit()}
              className={classes.actionButton}
            >
              Add Policy
            </Button>

            <Button
              variant='contained'
              color='default'
              onClick={() => addAllAlertPreferences()}
              className={classes.actionButton}
            >
              {isLoading ? 'Configuring...' : 'Configure All Policies'}
            </Button>
          </div>
        </div>
      </div>

      <div>
        {data.length === 0 && (
          <div className={classes.centerButton}>
            <p>No policies configured</p>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<PlaylistAddRoundedIcon />}
              onClick={() => addAlertPreferenceAndEdit()}
              className={classes.actionButton}
            >
              Add Policy
            </Button>
          </div>
        )}

        <Table<AllAlertPreference>
          loading={isLoading}
          columns={allColumns}
          data={allPrefs}
          rowKey={(el, id) => id.toString()}
        />

        {selectedAlertPreference && (
          <EditAlertPreference
            alertPreference={selectedAlertPreference}
            onClose={() => setSelectedAlertPreference(null)}
            allUsers={allUsers}
          />
        )}

        {selectedAlertCategory && (
          <EditAlertPreference
            alertCategory={selectedAlertCategory}
            onClose={() => setSelectedAlertCategory(null)}
            allUsers={allUsers}
          />
        )}
      </div>
    </div>
  );
};
