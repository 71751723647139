import { BadgeVariant } from 'components/Badge';
import { FindingSeverity } from 'dtos/application';
import { SecuritySeverity } from 'dtos/security-finding';
import { MFA_STATUSES, SIGN_IN_STATUSES } from 'telivy-constants';
import { ScoreRanking } from 'templates/SecurityAssessments/components/ScoreBox';

export const getMFAScoreRanking = (mfaStatus: MFA_STATUSES): ScoreRanking => {
  switch (mfaStatus) {
    case MFA_STATUSES.ENFORCED:
      return 'Low';
    case MFA_STATUSES.NOT_ENFORCED:
      return 'High';
    default:
      return 'None';
  }
};

export const getSeverityBadgeVariant = (severity?: SecuritySeverity | FindingSeverity): BadgeVariant => {
  switch (severity) {
    case SecuritySeverity.HIGH:
    case FindingSeverity.High:
      return 'red';
    case SecuritySeverity.MEDIUM:
    case FindingSeverity.Medium:
      return 'yellow';
    case SecuritySeverity.LOW:
    case FindingSeverity.Low:
      return 'blue';
    default:
      return 'grey';
  }
};

export const getSignInStatusRanking = (signInStatus: SIGN_IN_STATUSES): ScoreRanking => {
  switch (signInStatus) {
    case SIGN_IN_STATUSES.ENABLED:
      return 'Low';
    case SIGN_IN_STATUSES.DISABLED:
      return 'High';
    default:
      return 'None';
  }
};
