import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { LoadingContainer } from 'components/LoadingContainer';
import React, { useEffect } from 'react';
import { RouteProps } from 'react-router';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';

type PrivateRouteProps = RouteProps & {
  redirectToSignup?: boolean;
};

const ProtectedRoute = ({ redirectToSignup }: PrivateRouteProps) => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();
  const returnTo = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    if (isAuthenticated && !user?.email_verified) {
      return navigate(ROUTES.agent.DASHBOARD);
    }

    if (isLoading || isAuthenticated) {
      return;
    }

    const opts: RedirectLoginOptions = {
      screen_hint: redirectToSignup ? 'signup' : 'login',
      appState: { returnTo },
      fragment: window.location.search + (redirectToSignup ? `&signup=1` : ''),
    };

    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, returnTo, redirectToSignup, user, navigate]);

  return isAuthenticated ? <Outlet /> : <LoadingContainer fullScreen />;
};

export default ProtectedRoute;
