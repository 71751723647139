import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@material-ui/core';
import { CustomSnackbar } from 'components/CustomSnackbar';
import { LoadingContainer } from 'components/LoadingContainer';
import ProtectedRoute from 'components/ProtectedRoute';
import { SnackbarProvider } from 'notistack';
import { useCustomDomainAgent } from 'queries/useAgent';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { clearAuthTokens, setAuthTokens } from 'services/auth0-tokens';
import { insertIdToRoute, ROUTES } from 'telivy-constants';
import { TELIVY_THEME } from 'telivy-theme';
import { AdminView } from 'views/admin';
import { AgentView } from 'views/agent';
import { ClientView } from 'views/client';
import { SecurityAssessmentsView } from 'views/security';
import { ApplicationStartView } from 'views/start';
import { ApplicationThankYouGwsView } from 'views/thank-you/gws';
import { ApplicationThankYouM365View } from 'views/thank-you/m365';
import { VerifyApplicationAccessView } from 'views/verify';

import { AppHelment } from './AppHelmet';

const client = new QueryClient();

const NotFoundRouteHandler: React.FC = () => {
  const { data, isLoading } = useCustomDomainAgent(window.location.origin, {
    enabled: !window.location.origin.endsWith('telivy.com'),
  });

  if (!window.location.origin.endsWith('telivy.com')) {
    if (isLoading) {
      return <LoadingContainer fullScreen />;
    }

    if (data?.id) {
      return <Navigate replace to={insertIdToRoute(ROUTES.START, data.id)} />;
    }
  }

  return <Navigate replace to={ROUTES.agent.ROOT} />;
};

export const App: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [loading, setLoading] = useState(isLoading);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      getAccessTokenSilently()
        .then((token) => setAuthTokens({ accessToken: token }))
        .finally(() => {
          setLoading(false);
        });
    } else if (!isAuthenticated) {
      clearAuthTokens();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      setLoading(false);
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || loading) {
    return <LoadingContainer fullScreen />;
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      content={(key, message) => <CustomSnackbar id={key} message={message} />}
    >
      <ThemeProvider theme={TELIVY_THEME}>
        <QueryClientProvider client={client}>
          <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          <AppHelment />
          <Routes>
            {/* sign up views */}
            <Route path={ROUTES.SIGN_UP} element={<ProtectedRoute redirectToSignup />}>
              <Route path={ROUTES.SIGN_UP} element={<Navigate replace to='agent' />} />
            </Route>

            {/* misc */}
            <Route path={ROUTES.START} element={<ApplicationStartView />} />
            <Route path={ROUTES.VERIFY} element={<VerifyApplicationAccessView />} />
            <Route path={ROUTES.M365_THANK_YOU} element={<ApplicationThankYouM365View />} />
            <Route path={ROUTES.GWS_THANK_YOU} element={<ApplicationThankYouGwsView />} />

            {/* authenticated routes */}
            <Route path={ROUTES.agent.ROOT} element={<ProtectedRoute />}>
              {/* root view */}
              <Route index element={<Navigate replace to='dashboard' />} />

              {/* agent view */}
              <Route path={ROUTES.ALL} element={<AgentView />} />
            </Route>

            <Route path={ROUTES.ADMIN}>
              {/* admin views */}
              <Route index element={<AdminView />} />
            </Route>

            {/* client application */}
            <Route path={ROUTES.application.PARENT} element={<ClientView />}>
              <Route index element={<Navigate replace to={ROUTES.application.FORM} />} />
              <Route path={ROUTES.application.FORM} element={<ClientView />} />
              <Route path={ROUTES.application.security.ROOT} element={<SecurityAssessmentsView />} />
            </Route>

            {/* not found */}
            <Route path={ROUTES.ALL} element={<NotFoundRouteHandler />} />
          </Routes>
        </QueryClientProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default App;
